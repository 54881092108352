import Axios from 'axios';
import { Toast, qs } from './common';
import { baseURL, platform } from './config';
import { logger } from './logger';
import Store from './Store';

const lang = 'zh';

Axios.defaults.withCredentials = true;
var instance = Axios.create({
  baseURL,
  timeout: 60 * 1000,
  withCredentials: false,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

let errorConfig = {};
instance.interceptors.request.use(
  (config) => {
    if (!config.url.match(/lang=/g)) {
      if (!config.url.match(/\?/g)) {
        config.url += '?lang=' + lang;
      } else {
        config.url += '&lang=' + lang;
      }
    }
    let deviceId = localStorage.getItem('deviceId') || '';
    if (!config.url.match(/deviceId=/g)) {
      if (!config.url.match(/\?/g)) {
        config.url += '?deviceId=' + deviceId;
      } else {
        config.url += '&deviceId=' + deviceId;
      }
    }
    config.headers.token = window._token || Store.get('token');
    if (!config.url.match(/platform=/g)) {
      if (!config.url.match(/\?/g)) {
        config.url += '?platform=' + platform;
      } else {
        config.url += '&platform=' + platform;
      }
    }
    config.url += `&timestamp=${+new Date()}`;
    errorConfig = {
      url: config.url,
      data: config.data
    };
    // 在发送请求之前做些什么
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((data) => {
  if (data.config.disableErrorToast) {
    data.disableErrorToast = true;
    data.data && (data.data.disableErrorToast = true);
  }
  if (data.status == 200 && data.data && !data.data.error) {
    return data.data;
  } else {
    return Promise.reject(data);
  }
});

let whiteList = [];
instance.interceptors.response.use((res) => {
  return new Promise((resolve, reject) => {
    if (res.code == 0) {
      resolve(res.data);
    } else if (res.code == 1000) {
      reject(res.data);
      errorConfig.res = res;
    } else if (res.code == 403) {
      Store.set('token', '');
      window._token = '';
      window.location.reload();
    } else if (whiteList.indexOf(res.code) > -1) {
      resolve(res);
    } else {
      reject(res);
      errorConfig.res = res;
    }
  })
    .then((res) => {
      errorConfig = {};
      return res;
    })
    .catch((err) => {
      logger.error('请求错误errorConfig', errorConfig);
      logger.error(err);
      if (err && err.code) {
        if (err.code != 1001 && err.code !== 1009 && !err.disableErrorToast) {
          Toast(`连接超时,code=${err.code}`);
        }
      } else if (typeof err === 'string') {
        Toast(err);
      }
      throw err;
    });
});

export default instance;
