import Vue from 'vue';
import VueRouter from 'vue-router';
import Indicator from '../components/Indicator';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home'),
    meta: {
      title: '形白定制',
      keepAlive: true
    }
  },
  {
    path: '/createCard',
    name: 'createCard',
    component: () => import('@/views/createCard'),
    meta: {
      title: '形白定制',
      keepAlive: true
    }
  },
  {
    path: '/selectCard',
    name: 'selectCard',
    component: () => import('@/views/selectCard'),
    meta: {
      title: '选择尺寸规格',
      keepAlive: true
    }
  },
  {
    path: '/customCard',
    name: 'customCard',
    component: () => import('@/views/customCard'),
    meta: {
      title: '自定义规格'
    }
  },
  {
    path: '/cardDetail',
    name: 'cardDetail',
    component: () => import('@/views/cardDetail'),
    meta: {
      title: ''
    }
  },
  {
    path: '/orderCheck',
    name: 'orderCheck',
    component: () => import('@/views/orderCheck'),
    meta: {
      title: '购买并校验订单'
    }
  },
  {
    path: '/saveImage',
    name: 'saveImage',
    component: () => import('@/views/saveImage'),
    meta: {
      title: ''
    }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/orderDetail'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/skuDetail',
    name: 'skuDetail',
    component: () => import('@/views/skuDetail'),
    meta: {
      title: '证件照冲印'
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test'),
    meta: {
      title: ''
    }
  },
  {
    path: '/addPhoto',
    name: 'addPhoto',
    component: () => import('@/views/addPhoto'),
    meta: {
      title: '添加照片',
      keepAlive: true
    }
  },
  {
    path: '/editLifePhoto',
    name: 'editLifePhoto',
    component: () => import('@/views/editLifePhoto'),
    meta: {
      title: '编辑',
      keepAlive: true
    }
  },
  {
    path: '/editPld',
    name: 'editPld',
    component: () => import('@/views/editPld'),
    meta: {
      title: '编辑',
      keepAlive: true
    }
  },
  {
    path: '/editNameSticker',
    name: 'editNameSticker',
    component: () => import('@/views/editNameSticker'),
    meta: {
      title: '编辑',
      keepAlive: true
    }
  },
  {
    path: '/selectTemplate',
    name: 'selectTemplate',
    component: () => import('@/views/selectTemplate'),
    meta: {
      title: '选择模板',
      keepAlive: true
    }
  },
  {
    path: '/selectLifeTemplate',
    name: 'selectLifeTemplate',
    component: () => import('@/views/selectLifeTemplate'),
    meta: {
      title: '选择模板',
      keepAlive: true
    }
  },
  {
    path: '/editPb',
    name: 'editPb',
    component: () => import('@/views/editPb'),
    meta: {
      title: '编辑',
      keepAlive: true
    }
  },
  {
    path: '/editLifePb',
    name: 'editLifePb',
    component: () => import('@/views/editLifePb'),
    meta: {
      title: '编辑',
      keepAlive: true
    }
  },
  {
    path: '/fullscreen',
    name: 'fullscreen',
    component: () => import('@/views/fullscreen'),
    meta: {
      title: '编辑',
      keepAlive: true
    }
  },
  {
    path: '/pbDetail',
    name: 'pbDetail',
    component: () => import('@/views/pbDetail'),
    meta: {
      title: '拼版冲印'
    }
  },
  {
    path: '/finish',
    name: 'finish',
    component: () => import('@/views/finish'),
    meta: {
      title: '上传成功'
    }
  },
  {
    path: '/localMask',
    name: 'localMask',
    component: () => import('@/views/localMask'),
    meta: {
      title: '局部抠图'
    }
  },
  {
    path: '/editPaster',
    name: 'editPaster',
    component: () => import('@/views/editPaster'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/selectImage',
    name: 'selectImage',
    component: () => import('@/views/selectImage'),
    meta: {
      title: '编辑照片顺序'
    }
  },
  {
    path: '/editPhotoRoll',
    name: 'editPhotoRoll',
    component: () => import('@/views/editPhotoRoll'),
    meta: {
      title: '编辑'
    }
  },
  {
    path: '/photoRollTemplate',
    name: 'photoRollTemplate',
    component: () => import('@/views/photoRollTemplate'),
    meta: {
      title: '选择模板'
    }
  },
  {
    path: '/photoRollDetail',
    name: 'photoRollDetail',
    component: () => import('@/views/photoRollDetail'),
    meta: {
      title: '选择模板'
    }
  }
];

const router = new VueRouter({
  mode: 'hash', // history
  routes
});

import * as mwx from '@/utils/common.js';

// 设置一个存储上次导航时间的变量
let lastGoBackTime = 0;
// 设置一个最短允许的间隔（例如500毫秒）
const minNavigationInterval = 500; // 单位为毫秒

router.beforeEach((to, from, next) => {
  if (to.query.shopId) {
    localStorage.setItem('shopId', to.query.shopId);
  }
  const now = Date.now();
  // mwx.log.info(
  //   `beforeEach:to=${to.fullPath},from=${from.fullPath},time=${
  //     now - lastGoBackTime
  //   },to.name=${to.name}`
  // );
  Indicator.close();
  try {
    // 如果距离上次导航不足最小间隔，则阻止这次导航
    // console.log('now - lastGoBackTime', now - lastGoBackTime);
    // 限制以下路由短时间内导航多次
    if (
      now - lastGoBackTime < minNavigationInterval &&
      ['home', 'orderDetail', 'addPhoto'].indexOf(to.name) > -1
    ) {
      mwx.log.info('Too fast! Navigation blocked.');
      return next(false); // 阻止导航
    }

    // 更新最后一次导航时间
    lastGoBackTime = now;
    if (from.fullPath == '/') {
      // 刷新页面导致的重定向是允许多时间内导航多次的
      lastGoBackTime = 0;
    }
  } catch (error) {
    // console.error(error);
    // 处理错误
  }
  if (from.path == '/') {
    // 中间过程流程的页面手动刷新之后，需要回到首页
    let token = window._token || mwx.Store.get('token');
    // debugger;

    // 检查是否是返回操作

    let mustLogin = true;
    // mustLogin = false;
    if (
      (['createCard', 'saveImage'].indexOf(to.name) > -1 ||
        (!token && to.name != 'home')) &&
      mustLogin
    ) {
      if (!token && to.name != 'home' && to.query.soId) {
        let deviceId = localStorage.getItem('deviceId');
        if (!deviceId) {
          deviceId = mwx.createId(32);
          localStorage.setItem('deviceId', deviceId);
        }
        mwx.api
          .siteVisitorLogin({ deviceId, soId: to.query.soId })
          .then((r) => {
            window._token = r.token;
            mwx.Store.set('token', r.token);
            next();
            window.location.reload();
          })
          .catch((err) => {
            next(false);
            window.location.reload();
          });
        return;
      }
      mwx.log.error(
        `重定向到首页,to=${JSON.stringify(to)},from=${JSON.stringify(from)}`
      );
      next(false);
      window.location.reload();

      return;
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
