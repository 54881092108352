import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './utils/common';

// import * as fundebug from 'fundebug-javascript';
// import FundebugVue from 'fundebug-vue';

Vue.config.productionTip = false;
// import * as Sentry from '@sentry/vue';
// import { CaptureConsole } from '@sentry/integrations';
if (process.env.NODE_ENV === 'production') {
  // fundebug.init({
  //   silentWebsocket: true,
  //   apikey: '2d1366fed18d922b0679e61616b3a0b5db6457441a0e32668f62b573fe28b146'
  // });
  // new FundebugVue(fundebug).installVueErrorHandler(Vue); // Vue 2.x
  // window._Sentry = Sentry;
  // Sentry.init({
  //   Vue,
  //   dsn: 'https://3e239a8e23ba616deb37dc7df4976965@o4507614926471168.ingest.us.sentry.io/4507615542837248',
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //       tracePropagationTargets: [
  //         /^https:\/\/app\.elfinbook\.com/
  //         // /^http:\/\/app\.book\.com/
  //       ]
  //     }),
  //     new Sentry.Replay({
  //       maskAllText: false,
  //       blockAllMedia: false
  //     }),
  //     new CaptureConsole({ levels: ['warn', 'info', 'error'] })
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });
  // const BrowserLogger = require('alife-logger');
  // const __bl = BrowserLogger.singleton({
  //   pid: 'hizpqrpejx@e39d45e9fe79840',
  //   appType: 'web',
  //   imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
  //   sendResource: true,
  //   enableLinkTrace: true,
  //   behavior: true,
  //   enableSPA: true,
  //   enableConsole: true
  // });
  // window.__bl = __bl;
  // let a = c + 2;
}

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
