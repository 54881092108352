require('@babel/polyfill');
import '../assets/scss/reset.scss';
import '../assets/scss/common.scss';
import './setFontSize';
import Indicator from '../components/Indicator';
import Toast from '../components/Toast';
import * as api from '@/utils/api.js';
import Vue from 'vue';
import Store from './Store';
import $ from './myJQuery';
import report from './report';
import event from './event.js';
import action from './action.js';
import { Dialog } from 'vant';
import previewImage from '@/utils/previewImage.js';
import ClipboardJS from 'clipboard';
import slog from './slog.js';
import { logger, createLogFile, clearLogFile, getLogSize } from './logger.js';
window.logger = logger;

try {
  if (typeof globalThis === 'undefined') {
    window.globalThis = window;
  }
} catch (error) {}

function copyText(text) {
  let div = document.createElement('div');
  div.className = '__copy';
  document.body.appendChild(div);
  let clipboard = new ClipboardJS(div, {
    text: () => text
  });
  clipboard.on('success', (e) => {
    document.body.removeChild(div);
    // 成功回调
    e.clearSelection();
  });

  clipboard.on('error', (e) => {
    // 失败回调
  });
  div.click();
}

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error:
    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
  loading:
    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
  attempt: 1
});

window.$ = $;
window.Vue = Vue;
window.report = (eventId, label, data) => {
  try {
    report(eventId, label, data);
  } catch (error) {
    console.log(error);
  }
};

const qs = require('querystringify');
Vue.prototype.$qs = qs;

let isDev = process.env.NODE_ENV != 'production';

// 添加调试
if (
  /debugger/.test(location.href) ||
  sessionStorage.getItem('debugger') == 1 ||
  isDev
) {
  // TODO
  // 添加调试
  var script = document.createElement('script');
  var head = document.head || document.getElementsByTagName('head')[0];
  script.src = isDev
    ? location.origin + '/vconsole.js'
    : '//xingbaidesign.com/vconsole.js';
  head.appendChild(script);
  sessionStorage.setItem('debugger', 1);
  Vue.config.devtools = true;
  setTimeout(() => {
    window.VConsole && new window.VConsole();
  }, 3000);
}

function errorHandler(ex) {
  try {
    slog.error(ex);
  } catch (error) {}
}
if (process.env.NODE_ENV === 'production') {
  window.__bl = () => {};
  window.addEventListener('error', errorHandler);
  window.addEventListener('unhandledrejection', errorHandler);
}
Vue.config.errorHandler = function (err, vm, info) {
  errorHandler(err);
};

if (!window.innerWidth) {
  window.innerWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  window.innerHeight =
    document.documentElement.clientHeight || document.body.clientHeight;
}

Vue.prototype.setData = function (data) {
  for (let key in data) {
    this[key] = data[key];
  }
};

const atob = require('atob');
function loadImage(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.setAttribute('crossOrigin', 'Anonymous');
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = src;
  });
}

function setTitle(title) {
  document.title = title;
  if (/iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())) {
    var i = document.createElement('iframe');
    i.src = '//app.elfinbook.com/favicon.ico';
    i.style.display = 'none';
    i.onload = function () {
      setTimeout(() => {
        i.remove();
      }, 0);
    };
    document.body.appendChild(i);
  }
}

function stopScroll(selector) {
  console.log(
    'document.querySelector(selector)',
    document.querySelector(selector)
  );
  document.querySelector(selector).addEventListener(
    'touchmove',
    (e) => {
      e.preventDefault();
    },
    { passive: false }
  );
}

function delay(milliseconds) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}

function pollCheck(fn, ms = 100, timeout = 5000) {
  return new Promise((resolve, reject) => {
    let r = fn();
    if (r) {
      resolve(r);
      return;
    }
    let timer1 = setTimeout(() => {
      clearInterval(timer);
      timer = null;
      clearTimeout(timer1);
      timer1 = null;
      reject('timeout');
      console.error('pollCheck fn', fn);
    }, timeout);
    let timer = setInterval(() => {
      try {
        let r = fn();
        if (r) {
          resolve(r);
          clearInterval(timer);
          timer = null;
          clearTimeout(timer1);
          timer1 = null;
        }
      } catch (error) {
        reject(error);
        clearInterval(timer);
        timer = null;
        clearTimeout(timer1);
        timer1 = null;
      }
    }, ms);
  });
}

function saveImageToAlbum(imageSrc) {
  return new Promise((resolve) => {
    if (/^http/.test(imageSrc)) {
      window.bridge.callHandler(
        'saveImg',
        {
          imgUrl: imageSrc
        },
        (res) => {
          let data = JSON.parse(res);
          if (data.code == 'success') {
            resolve();
          } else {
            reject(data.code);
          }
        }
      );
    } else {
      window.bridge.callHandler(
        'saveImg',
        {
          imgBase64String: imageSrc
        },
        (res) => {
          let data = JSON.parse(res);
          if (data.code == 'success') {
            resolve();
          } else {
            reject(data.code);
          }
        }
      );
    }
  });
}

/**
 * 防止重复点击,在func执行完成之后才可以再次执行func
 * @param {Function} func
 * @param {boolean} [manual=false] 是否手动解锁,默认为false,会在func函数resolve之后自动进行解锁。
 * @returns
 */
function ignoreMultiClick(func, manual = false) {
  let lock = false;
  return function (...args) {
    if (lock) return;
    lock = true;
    let done = () => (lock = false);
    if (manual) return func.call(this, ...args, done);
    let promise = func.call(this, ...args);
    Promise.resolve(promise).finally(done); // func可以不返回Promise对象
    return promise;
  };
}

let fileToBase64Cache = {};
let base64ToFileCache = {};
function base64ToFile(dataUrl, filename = '1.png') {
  try {
    let cacheKey = dataUrl.slice(-100, dataUrl.length);
    if (base64ToFileCache[cacheKey] && 0) {
      return base64ToFileCache[cacheKey];
    }
    var arr = dataUrl.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    let file = new File([u8arr], filename, { type: mime });
    u8arr = null;
    // base64ToFileCache[cacheKey] = file;
    return file;
  } catch (error) {
    slog.log('base64ToFile', error);
    throw error;
  }
}

// 从File对象读取文件并转换为Base64编码
function fileToBase64(file, opt = {}) {
  let cacheKey = JSON.stringify({
    name: file.name,
    size: file.size,
    type: file.type,
    lastModified: file.lastModified
  });
  if (!opt) {
    opt = {};
  }
  // debugger;
  if (fileToBase64Cache[cacheKey] && !opt.noCache) {
    return Promise.resolve(fileToBase64Cache[cacheKey]);
  }

  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function (event) {
      const base64String = event.target.result;
      resolve(base64String);
      if (!opt.noCache) {
        fileToBase64Cache[cacheKey] = base64String;
        base64ToFileCache[base64String.slice(-100, base64String.length)] = file;
      }
      reader = null;
    };
    reader.onerror = function (error) {
      slog.log('fileToBase64 error', error);
      reject(error);
      reader = null;
    };
    reader.readAsDataURL(file);
  });
}

function parseIntPrice(v) {
  if (v == parseInt(v)) {
    return parseInt(v);
  } else {
    return v;
  }
}

function calcMaxSize(width, height, maxWidth, maxHeight) {
  let ratio = width / height;
  let w;
  let h;
  if (ratio > maxWidth / maxHeight) {
    w = maxWidth;
    h = w / ratio;
  } else {
    h = maxHeight;
    w = h * ratio;
  }
  return {
    width: w,
    height: h
  };
}

function calcCenterSize(width, height, maxWidth, maxHeight) {
  let ratio = width / height;
  let w;
  let h;
  if (ratio > maxWidth / maxHeight) {
    h = maxHeight;
    w = h * ratio;
  } else {
    w = maxWidth;
    h = w / ratio;
  }
  return {
    width: w,
    height: h
  };
}

function getSideLength(p1, p2) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}
function getAngleFromThreeSideLength(a, b, c) {
  return (Math.acos((c * c - a * a - b * b) / -2 / a / b) * 180) / Math.PI;
}

function timeout(pro, time) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(timeout);
    }, time);
    pro.then((r) => {
      resolve(r);
    });
  });
}

async function repeatPromise({ promiseCb, repeatCount, repeatInterval }) {
  let count = 0;
  let result;
  while (count < repeatCount) {
    try {
      result = await promiseCb(count);
      return result;
    } catch (error) {
      count++;
      if (count == repeatCount) {
        throw error;
      }
      slog.log('\nrepeatPromise:', repeatCount);
      await delay(repeatInterval);
    }
  }
}

function _getImageInfo(imageUrl) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    if (/http/.test(imageUrl)) {
      img.crossOrigin = 'anonymous';
    }
    img.onload = () => {
      if (img.naturalWidth) {
        img.width = img.naturalWidth;
        img.height = img.naturalHeight;
      }
      resolve(img);
    };
    img.onerror = (err) => {
      getImgErrorCode(imageUrl);
      reject(err);
    };
    img.src = imageUrl;
  });
}

function addParams(url, params) {
  if (!/^http/.test(url)) {
    return url;
  }
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}${queryString}`;
}

let imageInfoCacheList = [];
let maxCacheCount = 30;
function getImageInfo(imageUrl, repeatCount = 5, disableCache = false) {
  let cache = imageInfoCacheList.filter((v) => v.imageUrl === imageUrl)[0];
  if (cache && !disableCache) {
    return Promise.resolve(cache.data);
  }
  return repeatPromise({
    promiseCb: (index) =>
      _getImageInfo(index == 0 ? imageUrl : addParams(imageUrl, { t: index })),
    repeatCount,
    repeatInterval: 1000
  })
    .then((r) => {
      imageInfoCacheList.push({
        imageUrl,
        data: r
      });
      if (imageInfoCacheList.length > maxCacheCount) {
        imageInfoCacheList.shift();
      }
      return r;
    })
    .catch((r) => {
      debugger;
      slog.log(`getImageInfo repeat fail:imageSrc=${imageUrl},error:`, r);
      throw r;
    });
}

function getBase64MimeType(base64String) {
  // 使用正则表达式匹配data URI格式的头部信息
  const matches = base64String.match(/^data:(.*?);base64,/);

  // 如果匹配成功且有MIME类型，则返回MIME类型
  if (matches && matches[1]) {
    return matches[1];
  }

  // 如果没有匹配成功，则返回null或者适当的默认值
  return null;
}

function createImage(imageSrc) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = imageSrc;
  });
}

async function useCompressImage({
  imageSrc,
  maxWidth,
  maxHeight,
  imageType = 'image/jpeg',
  specifiedWidth,
  specifiedHeight
}) {
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');
  let mimeType = imageType;
  let imageInfo = await getImageInfo(imageSrc);
  let width = imageInfo.width;
  let height = imageInfo.height;

  if (width > specifiedWidth && height > specifiedHeight) {
    width = specifiedWidth;
    height = specifiedHeight;
  } else if (width > maxWidth || height > maxHeight) {
    const ratio = Math.min(maxWidth / width, maxHeight / height);
    width = Math.round(width * ratio);
    height = Math.round(height * ratio);
  }

  canvas.width = width;
  canvas.height = height;

  let img = await createImage(imageSrc);

  // 在canvas上绘制压缩后的图片
  ctx.drawImage(img, 0, 0, width, height);

  // 将canvas转换为DataURL格式
  let quality = 1;
  if (imageSrc.length / 1024 / 1024 > 10) {
    quality = 0.92;
  }

  let compressedDataURL = canvas.toDataURL(mimeType, quality); // 或 'image/png'，取决于你的需求
  if (compressedDataURL == 'data:,') {
    slog.log(
      `compressedDataURL data fail,width=${width},height=${height},specifiedWidth=${specifiedWidth},specifiedHeight=${specifiedHeight}`
    );
    return imageSrc;
  }
  // slog.log(
  //   `压缩前=${parseInt(imageSrc.length / 1024 / 1024)}mb,压缩后=${parseInt(
  //     compressedDataURL.length / 1024 / 1024
  //   )}mb`
  // );
  // downloadFile(compressedDataURL);
  // 显示压缩后的图片
  canvas = null;
  ctx = null;
  return compressedDataURL;
}

const log = logger;

function downloadFile(imgDataUrl, filename = 'image.png') {
  var a = document.createElement('a');
  a.href = imgDataUrl;
  a.download = filename; // 设置下载文件的名称
  a.click(); // 触发链接点击以下载图像
}

function showLoading(text, opt) {
  if (typeof opt != 'object') {
    opt = {};
  }
  Indicator.open(text || '加载中...', opt);
}
function hideLoading(delay) {
  if (delay) {
    setTimeout(() => {
      Indicator.close();
    }, delay);
  } else {
    Indicator.close();
  }
}

async function checkImageRisk(img, opt = {}) {
  let info = await api.checkImage(img, opt);
  // info.checkStatus = -1;
  if (info.checkStatus == -1) {
    Toast('检测到有图片存在违规内容', false, 4000);
    throw new Error('检测到有图片存在违规内容');
  } else if (info.checkStatus == -2) {
    if (info.statusCode == 413) {
      Toast('图片大小超过限制', false, 4000);
      throw new Error('图片大小超过限制');
    } else {
      Toast('图片上传超时,请重新上传', false, 4000);
      throw new Error('图片上传超时,请重新上传');
    }
  }
  return info;
}

function getApp() {
  return {
    globalData: {
      px2rpx: 2,
      setting: {
        useLocalCrop: true
      }
    }
  };
}

function confirmModal(opt) {
  if (opt.showCancelButton === undefined) {
    opt.showCancelButton = true;
  }
  return Dialog.confirm({
    title: '',
    message: opt.content,
    confirmButtonText: opt.confirmText,
    cancelButtonText: opt.cancelText,
    confirmButtonColor: '#576B95',
    showCancelButton:
      opt.showCancel !== undefined ? opt.showCancel : opt.showCancelButton,
    closeOnPopstate: false
  })
    .then((r) => {
      return 1;
    })
    .catch((err) => {
      return -1;
    });
}

function getImgFileSize(file) {
  return new Promise((resolve, reject) => {
    // 创建一个 Image 对象
    const img = new Image();

    // 设置 Image 对象的 src 属性为选择的图片文件的URL
    img.src = URL.createObjectURL(file);

    // 等待图片加载完成
    img.onload = function () {
      // 获取图片的宽度和高度
      resolve(img);
      // slog.log('getImgFileSize', file, img.width, img.height);
    };
    img.onerror = function (err) {
      reject(err);
    };
  });
}

/**
 * 传入一个函数队列，每个函数函数执行回返回一个Promise。同时只能有max个promise在执行，所有promise执行完毕后返回一个promise
 * @param {Array} returnPromiseCallbackList 数组中的每一项都是一个函数，函数返回一个promise
 * @param {number} [max=1] promise的最大并发数
 * @returns Promise 所有promise执行完毕后promise
 */
function limitConcurrency(returnPromiseCallbackList, max = 1) {
  return new Promise((resolve) => {
    let index = 0;
    let running = 0;
    let results = [];
    if (!returnPromiseCallbackList.length) {
      resolve(results);
      return;
    }

    function run() {
      while (running < max && index < returnPromiseCallbackList.length) {
        running++;
        const currentIndex = index;
        const promise = returnPromiseCallbackList[index]();
        index++;
        promise
          .then((result) => {
            results[currentIndex] = result;
          })
          .finally(() => {
            running--;
            if (index === returnPromiseCallbackList.length && running === 0) {
              resolve(results);
            } else {
              run();
            }
          });
      }
    }
    run();
  });
}

/**
 * 创建指定长度的随机字符串
 * @param {Number} length
 * @returns
 */
function createId(length) {
  let result = '';
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function removeUnUseStorage() {
  slog.log('--removeUnUseStorage--');
  let keys = [
    'highOriginConfig',
    'cardImage',
    'sixCardImage',
    'singleCardCreateInfo',
    'PrevCreateInfo'
  ];
  keys.forEach((key) => {
    Store.remove(key);
  });
}

// 获取纸张的px信息
// 注： 返回的width始终是大于height的，但实际预览和编辑视图中进行了旋转，width是小于height的
function getPhotoSizeInfo(info) {
  let newW = Math.round((info.paperWidth / 25.4) * 300);
  let newH = Math.round((info.paperHeight / 25.4) * 300);
  return {
    width: newW,
    height: newH
  };

  // 六寸为基准
  // 六寸尺寸15.2cm*10.2cm   6=15.2/2.54
  // 5寸尺寸 12.7cm*8.9cm    5=12.7/2.54
  // 3寸尺寸 5.5cm*8.4cm    3=12.7/2.54
  // 57/25.4*300
  let w = 1793;
  let h = 1203;
  let width;
  let height;
  // info.inch = 6;
  if (info.inch == 6) {
    width = w;
    height = h;
    return {
      width,
      height
    };
  } else if (info.inch == 5) {
    width = Math.round((12.7 / 15.2) * w);
    height = Math.round((8.9 / 10.2) * h);
    return {
      width,
      height
    };
  } else {
    let newW = Math.round((info.paperWidth / 25.4) * 300);
    let newH = Math.round((info.paperHeight / 25.4) * 300);
    if (newW < newH) {
      return {
        width: newH,
        height: newW
      };
    } else {
      return {
        width: newW,
        height: newH
      };
    }
  }
}

function isOnlineImage(imageSrc) {
  return /^http/.test(imageSrc);
}

function getImgErrorCode(imgSrc) {
  return;
  if (!imgSrc) {
    if (!window._isFirstDebugger) {
      debugger;
      window._isFirstDebugger = true;
    }
    slog.error('getImgErrorCode imgSrc is null');
    return;
  }
  var img = new Image();
  img.src = imgSrc;
  return new Promise((resolve, reject) => {
    img.onload = () => {
      slog.log('getImgErrorCode success', imgSrc);
    };
    img.onerror = function () {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', imgSrc, true);
      xhr.onload = function () {
        // 获取HTTP状态码
        var status = xhr.status;
        slog.log(
          `图片加载失败=${
            imgSrc ? imgSrc.slice(0, 300) : ''
          }\n,状态码: ${status}`
        );
        resolve(status);
        // 处理其他相关操作
      };
      xhr.onerror = function (err) {
        slog.log(`图片加载失败=${imgSrc ? imgSrc.slice(0, 300) : ''}\n`);
        slog.error(err);
      };
      xhr.send();
    };
  });
}

function isPC() {
  var userAgentInfo = navigator.userAgent;
  var agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ];
  var flag = true;
  for (var i = 0; i < agents.length; i++) {
    if (userAgentInfo.indexOf(agents[i]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

function convertCropInfo(data, type, opt = {}) {
  function fixed(num) {
    return parseInt(num || 0);
  }
  // 前端数据结构转换为后台结构
  if (type == 'toServer') {
    let x = fixed(data.imageInfo.x);
    let y = fixed(data.imageInfo.y);
    let w = fixed(data.imageInfo.width);
    let h = fixed(data.imageInfo.height);
    let width = fixed(data.canvasWidth);
    let height = fixed(data.canvasHeight);
    if (data.layoutType == 'fill') {
      // 填充模式  图片的一边和内框的一边对齐
      let diffW = Math.abs(w - width);
      let diffH = Math.abs(h - height);
      // fix: 1px误差
      if (diffW < diffH) {
        // 宽度对齐
        if (diffW <= 1) {
          w = width;
          x = 0;
        }
      } else {
        // 长度对齐
        if (diffH <= 1) {
          h = height;
          y = 0;
        }
      }
    }
    // 修复小数误差
    if (data.layoutType == 'fit') {
      let x1 = (width - w) / 2;
      let y1 = (height - h) / 2;
      if (y == 0 && Math.abs(x - x1) <= 1) {
        x = Number(x1.toFixed(1));
      } else if (x == 0 && Math.abs(y - y1) <= 1) {
        y = Number(y1.toFixed(1));
      }
      if (Math.abs(width - w) <= 1) {
        w = width;
      }
      if (Math.abs(height - h) <= 1) {
        h = height;
      }
    }
    let result = {
      layout: data.layoutType == 'fit' ? 'fit' : 'fill',
      border: fixed(data.whiteBorder),
      x,
      y,
      w,
      h,
      width,
      height,
      mode: data.mode || 0
    };
    return result;
  } else {
    return {
      layoutType: data.layout, // 填充方式
      whiteBorder: data.border, // 是否有白边
      imageInfo: {
        // 图片在内框的位置和大小
        x: data.x,
        y: data.y,
        width: data.w,
        height: data.h
      },
      canvasWidth: data.width, // 拍立得内框的宽高（不包含边框）
      canvasHeight: data.height,
      mode: data.mode || 0 // 用户是否修改过   0：默认  1：移动 2：旋转
    };
  }
}

function fileToBlobUrl(file) {
  slog.log('fileToBlobUrl before');
  let r = (window.URL || window.webkitURL).createObjectURL(file);
  if (!window.__fileUrlMap) {
    window.__fileUrlMap = {};
  }
  window.__fileUrlMap[r] = file;
  slog.log(`fileToBlobUrl after:isFile=${file instanceof File},url=${r}`);
  return r;
}

function getFileFromBlobUrl(blobUrl) {
  try {
    if (!window.__fileUrlMap[blobUrl]) {
      slog.log('getFileFromBlobUrl fail', blobUrl);
    }
    return window.__fileUrlMap[blobUrl];
  } catch (error) {
    return '';
  }
}

const failReasonMap = {
  rotateFail: '当前图片格式暂不支持',
  minifyFail: '当前图片格式暂不支持',
  imageRisk: '图片存在违规内容',
  uploadFail: '图片上传失败',
  convertFail: '图片格式转换失败',
  fileToUrlFail: '当前图片格式暂不支持',
  imageTypeError: '当前图片格式暂不支持',
  getImageInfoFail: '获取图片信息失败',
  createImageFail: '当前图片格式暂不支持',
  imageRatioFail: '检测到当前图片数据异常',
  imageRetouchFail: '当前图片超过抠图限制'
};

function getFailInfo(key) {
  if (key && key.failReason && key.failResolve) {
    return key;
  }
  if (key && key.message) {
    key = key.message;
  }
  let defaultInfo = {
    failReason: '当前图片格式暂不支持',
    failResolve: '请删除后选择其他图片'
  };
  let failList = [
    {
      failReason: failReasonMap.rotateFail,
      failResolve: '请手动旋转图片后再上传'
    },
    {
      failReason: failReasonMap.minifyFail,
      failResolve: '请删除后重试或更换图片'
    },
    {
      failReason: failReasonMap.imageRisk,
      failResolve: '请更换图片后再上传'
    },
    {
      failReason: failReasonMap.uploadFail,
      failResolve: '请删除后重试或更换图片'
    },
    {
      failReason: failReasonMap.convertFail,
      failResolve: '请删除后重试或更换图片'
    },
    {
      failReason: failReasonMap.fileToUrlFail,
      failResolve: '请删除后更换图片'
    },
    {
      failReason: failReasonMap.getImageInfoFail,
      failResolve: '请删除后重试或更换图片'
    },
    {
      failReason: failReasonMap.createImageFail,
      failResolve: '请删除后更换图片'
    },
    {
      failReason: failReasonMap.imageTypeError,
      failResolve: '请删除后更换图片'
    },
    {
      failReason: failReasonMap.imageRatioFail,
      failResolve: '请重新上传图片'
    },
    {
      failReason: failReasonMap.imageRetouchFail,
      failResolve: '请更换图片'
    }
  ];
  let ind = failList.findIndex((v) => v.failReason == key);
  return ind == -1 ? defaultInfo : failList[ind];
}

function errorToString(err) {
  try {
    if (err instanceof Error) {
      window._Sentry && window._Sentry.captureException(err);
    }
  } catch (error) {
    slog.error(error);
    // 处理错误
  }
  isDev && slog.error(err);
  let str = '';
  if (err && err.message) {
    str += err.message || err;
    if (err.stack) {
      str += '\n' + err.stack;
    }
    return str;
  } else {
    return err;
  }
}
const dcraw = require('dcraw');
function convertRawCameraImage(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = (function (o) {
      return function (e) {
        // Get the image file as a buffer
        var buf = new Uint8Array(e.currentTarget.result);

        // Extract the thumbnail
        var thumbnail = dcraw(buf, { extractThumbnail: true });
        // Create thumbnail
        var blob = new Blob([thumbnail], { type: 'image/jpeg' });
        var file = new Blob([blob], { type: 'image/jpeg' });
        resolve(file);
      };
    })(file);
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsArrayBuffer(file);
  });
}

function getDomDetail(selector) {
  return document.querySelector(selector).getBoundingClientRect();
}

function saveImage(dataURL) {
  // 创建一个a元素
  var link = document.createElement('a');
  link.href = dataURL;
  link.download = 'image.png';

  // 模拟点击下载链接
  link.click();
}

function checkIsPc() {
  var userAgentInfo = navigator.userAgent;
  var agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ];
  var flag = true;
  for (var i = 0; i < agents.length; i++) {
    if (userAgentInfo.indexOf(agents[i]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

let isPc = checkIsPc();

function polyfillForPcTouches(e) {
  try {
    if (isPc || !e.touches || !e.touches.length) {
      e.touches = [{ pageX: e.pageX, pageY: e.pageY }];
    }
  } catch (error) {
    console.error(error);
    // 处理错误
  }
  return e;
}

window.globalData = {};

function isWebPSupportedWithImage() {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = function () {
      // 图片加载成功且宽高非零则表示支持WebP
      if (img.width !== 0 && img.height !== 0) {
        // slog.log('Browser supports WebP.');
        resolve(1);
        return true;
      } else {
        resolve(-1);
        slog.log('Could not properly load the image, might not support WebP.');
        return false;
      }
    };
    img.onerror = function () {
      // 加载失败，通常表示不支持WebP格式
      slog.log('Browser does not support WebP.');
      resolve(-1);
      return false;
    };

    // 使用一个已知的WebP图片URL
    img.src =
      'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  });
}
let isSupprtWebp = true;
isWebPSupportedWithImage().catch((r) => {
  isSupprtWebp = false;
});

function webpToJpg(imageSrc) {
  return new Promise((resolve, reject) => {
    var image = new Image();

    image.onload = function () {
      var canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;
      canvas.getContext('2d').drawImage(this, 0, 0);
      let r = canvas.toDataURL('image/jpeg');
      resolve(r);
    };
    image.onerror = function (err) {
      reject(err);
    };
    image.src = imageSrc;
  });
}

// skuType: https://www.showdoc.com.cn/2272359968911745/10188142564529487
const cardTypeList = [1]; // 证件照
const pbTypeList = [4]; // 拼版冲印
const lifeTypeList = [2]; // 生活照
const nameStickerTypeList = [15]; // 姓名贴
const pldSkyTypeList = [3, 9, 10, ...nameStickerTypeList]; // 拍立得
const lifePbTypeList = [11, 12]; //  人生四格
const pasterTypeList = [13]; // 异型
const photoRollTypeList = [14]; // 照片卷
const supportDivideUploadList = [
  ...lifeTypeList,
  ...pldSkyTypeList,
  ...lifePbTypeList,
  ...pasterTypeList
]; // 支持拆分上传的类型

function setClipBoard({ text }) {
  return new Promise((resolve, reject) => {
    import(/* webpackChunkName: "clipboard" */ 'clipboard').then((v) => {
      let div = document.createElement('div');
      div.className = '__copy';
      let ClipboardJS = v.default;
      let clipboard = new ClipboardJS(div, {
        text: () => text
      });
      clipboard.on('success', (e) => {
        // 成功回调
        e.clearSelection();
        resolve();
      });

      clipboard.on('error', (e) => {
        reject();
      });
      div.click();
    });
  });
}

function getPhotoList(query) {
  let data = Store.get('photoListData') || {};
  let orderNo = `soId=${query.soId}&outerOiId=${query.outerOiId}&lifeSubIndex=${query.lifeSubIndex}`;
  if (!orderNo) {
    Toast(`orderNo不存在`);
    // debugger;
    // throw new Error('orderNo不存在');
  }
  let list = data[orderNo] || [];
  return JSON.parse(JSON.stringify(list));
}

function setPhotoList({ query, list }) {
  let orderNo = `soId=${query.soId}&outerOiId=${query.outerOiId}&lifeSubIndex=${query.lifeSubIndex}`;
  if (!orderNo) {
    // debugger;
    Toast(`orderNo不存在`);
    // throw new Error('orderNo不存在');
  }
  let data = Store.get('photoListData') || {};
  if (!list.length) {
    // debugger;
  }
  data[orderNo || 'default'] = list;
  Store.set('photoListData', data, () => {
    let r = {};
    r[orderNo || 'default'] = list;
    Store.set('photoListData', r);
  });
}

async function uploadLogFile(notClear = false, warning = 0) {
  let file = await createLogFile();
  if (file.size == 0) {
    return;
  }
  let res = await api.checkImage(file, {
    mediaType: 'log',
    poi: 0,
    temporary: 0,
    permanent: 1
  });
  res.warning = warning;
  if (!res.mediaId) {
    return;
  }
  let soId = sessionStorage.getItem('soId') || '';
  res.logCategory = soId;
  await api.wechatLog(res);
  // if (!notClear) {
  //   clearLogFile();
  // }
}

// 超过5mb自动上传
// if (getLogSize > 5 * 1024 * 1024) {
// uploadLogFile();
// }

const TextBottom = 0;
const TextLeft = 1;

function isSVGSupported() {
  return (
    !!document.createElementNS &&
    !!document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      .createSVGRect
  );
}

function removeExif({ file, maxWidth = 5000, maxHeight = 5000 }) {
  return new Promise((resolve, reject) => {
    import('blueimp-load-image').then(
      (r) => {
        let startTime = new Date();
        r.default(file, (img) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let { width, height } = calcMaxSize(
            img.naturalWidth,
            img.naturalHeight,
            maxWidth,
            maxHeight
          );
          canvas.width = width;
          canvas.height = height;
          if (img.type === 'error') {
            reject(new Error('图片加载失败'));
          } else {
            // 将 img 绘制到 canvas 上
            ctx.drawImage(
              img,
              0,
              0,
              img.naturalWidth,
              img.naturalHeight,
              0,
              0,
              width,
              height
            );

            // 将 canvas 转换为 data URL (Base64)
            const base64DataURL = canvas.toDataURL('image/jpeg', 0.8); // 指定输出图像的类型

            // const link = document.createElement('a');
            // link.href = base64DataURL;
            // link.download = 'image.jpg';
            // link.click();

            // 设置 img 的 src 为 Base64 编码的数据 URL
            console.log('removeExif', new Date() - startTime);
            resolve(base64DataURL);
          }
        });
      },
      {
        maxWidth,
        maxHeight
      }
    );
  });
}

const EXIF = require('exif-js');

function processExifData(imgSrc) {
  return new Promise((resolve, reject) => {
    let startTime = new Date().getTime();
    const image = new Image();
    image.src = imgSrc;
    image.onload = function () {
      EXIF.getData(image, function () {
        try {
          var orientation = EXIF.getTag(this, 'Orientation');
          var rotationInfo = checkIfRotated(orientation);
          console.log('exif parse', new Date() - startTime);
          resolve(rotationInfo); // Resolve with the rotation information
        } catch (error) {
          reject(error); // Reject on error
        }
      });
      image.onerror = reject;
    };
  });
}

function checkIfRotated(orientation) {
  if (!orientation || orientation === 1) {
    return { rotated: false, message: 'The image is not rotated.' };
  } else {
    let rotationMessage;
    switch (orientation) {
      case 3:
        rotationMessage = 'Rotate 180 degrees';
        break;
      case 6:
        rotationMessage = 'Rotate 90 degrees CW';
        break;
      case 8:
        rotationMessage = 'Rotate 90 degrees CCW';
        break;
      default:
        rotationMessage = 'Other rotation values may need special handling';
    }
    return {
      rotated: true,
      message: rotationMessage,
      orientation: orientation
    };
  }
}

async function removeFileExif(file) {
  if (file.size == 0) {
    throw new Error('file size is 0');
  } else {
    if (file instanceof File || file instanceof Blob) {
      let imageSrc = await fileToBase64(file);
      let exifInfo = await processExifData(imageSrc);
      if (exifInfo.rotated) {
        return await removeExif({ file });
      } else {
        return imageSrc;
      }
    } else {
      throw new Error('file is not a file or blob');
    }
  }
}

function copyUrlParams(str = window.location.href) {
  const queryParams = {};
  const queryString = str.split('?')[1];
  if (!queryString) return '';

  const pairs = queryString.split('&');
  for (const pair of pairs) {
    const [key, value] = pair.split('=');
    queryParams[key] = value || '';
  }
  let result = '';
  queryParams.t = +new Date();
  Object.keys(queryParams).forEach((key) => {
    result += `${key}=${queryParams[key]}&`;
  });
  result = result.replace(/\&$/, '');
  return result;
}

function deepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

function toFixed(number, precision) {
  return parseFloat(number.toFixed(precision));
}

function getStore({ namespace, key }) {
  let info = Store.get(namespace) || {};
  return info[key];
}

function setStore({ namespace, key, val }) {
  let info = Store.get(namespace) || {};
  info[key] = val;
  return Store.set(namespace, info);
}

export {
  Indicator,
  showLoading,
  hideLoading,
  Toast,
  api,
  qs,
  loadImage,
  setTitle,
  stopScroll,
  delay,
  saveImageToAlbum,
  ignoreMultiClick,
  base64ToFile,
  parseIntPrice,
  calcMaxSize,
  calcCenterSize,
  getSideLength,
  getAngleFromThreeSideLength,
  timeout,
  getImageInfo,
  useCompressImage,
  log,
  downloadFile,
  report,
  event,
  Store,
  action,
  checkImageRisk,
  getApp,
  confirmModal,
  fileToBase64,
  getImgFileSize,
  pollCheck,
  previewImage,
  limitConcurrency,
  createId,
  removeUnUseStorage,
  getPhotoSizeInfo,
  isOnlineImage,
  copyText,
  getImgErrorCode,
  repeatPromise,
  isPC,
  isDev,
  getBase64MimeType,
  convertCropInfo,
  fileToBlobUrl,
  getFileFromBlobUrl,
  errorHandler,
  getFailInfo,
  failReasonMap,
  slog,
  errorToString,
  convertRawCameraImage,
  getDomDetail,
  saveImage,
  isPc,
  polyfillForPcTouches,
  isWebPSupportedWithImage,
  webpToJpg,
  isSupprtWebp,
  cardTypeList,
  pbTypeList,
  lifeTypeList,
  nameStickerTypeList,
  pldSkyTypeList,
  createLogFile,
  setClipBoard,
  getPhotoList,
  setPhotoList,
  uploadLogFile,
  lifePbTypeList,
  supportDivideUploadList,
  TextBottom,
  TextLeft,
  isSVGSupported,
  pasterTypeList,
  removeExif,
  processExifData,
  removeFileExif,
  copyUrlParams,
  photoRollTypeList,
  deepCopy,
  toFixed,
  getStore,
  setStore
};
