import Store from './Store.js';
import dayjs from 'dayjs';
// 为了防止拼写错误，对每个键值的存储声明一个函数

let isListenGetPrevCreateInfo = false;

let ac = {
  // 存储 soId、outerOiId、template等数据
  getCreateCardInfo: () => Store.get('CreateCardInfo'),
  setCreateCardInfo: (val) => {
    let data = Store.get('CreateCardInfo') || {};
    Store.set('CreateCardInfo', {
      ...data,
      ...val
    });
  },
  // 支持之前的证件制作情况 {name,background,imageUrlList}
  getPrevCreateInfo: () => Store.get('PrevCreateInfo'),
  setPrevCreateInfo: (val) => {
    let data = Store.get('PrevCreateInfo') || {};
    Store.set('PrevCreateInfo', {
      ...data,
      ...val
    });
    // try {
    //   if (!isListenGetPrevCreateInfo) {
    //     isListenGetPrevCreateInfo = true;
    //     window.addEventListener('message', function (res) {
    //       let data = Store.get('PrevCreateInfo');
    //       console.log('---getPrevCreateInfo---', res);
    //       if (res.data && res.data.action == 'getPrevCreateInfo') {
    //         window.postMessage('setPrevCreateInfo', {
    //           action: 'setPrevCreateInfo',
    //           data
    //         });
    //       }
    //     });
    //   }
    // } catch (error) {
    //   mwx.log.error(error);
    // }
  },
  /*
   后台返回的用户信息
    {
      "uid": 213486,
      "prefix": 86,
      "username": "18868195026",
      "email": null,
      "level": 0,
      "expireTime": null,
      "headImg": "http://img.elfinbook.com/avatar/213486202005225ec7456468d48.jpg",
      "nickname": "L",
      "language": "zh_CN",
      "cloudSpaceSize": 524288000,
      "baseSpaceSize": 524288000,
      "vipCloudSpaceSize": 0,
      "purchaseCloudSpaceSize": 0,
      "usedSpaceSize": 58626214,
      "accessToken": "yqpEx2aw-J",
      "token": "",
      "rememberMe": true
    }
  */
  getUserInfo: () => Store.get('userInfo'),
  setUserInfo: (val) => {
    let prev = Store.get('userInfo') || {};
    if (prev.token && !val.token) {
      val.token = prev.token;
    }
    let userInfo = {
      ...prev,
      ...val,
      level: 1
    };
    Store.set('userInfo', userInfo);
  },
  getSkuDetailToCardDetailIndex: () => Store.get('SkuDetailToCardDetailIndex'),
  setSkuDetailToCardDetailIndex: (val) =>
    Store.set('SkuDetailToCardDetailIndex', val),
  getHistoryStackMap: () => Store.get('HistoryStackMap'),
  setHistoryStackMap: (val) => Store.set('HistoryStackMap', val),
  // 自定义顶部栏的高度 [Number]
  getNavBarHeight: () => Store.get('navBarHeight'),
  setNavBarHeight: (val) => Store.set('navBarHeight', val),

  // 抠图处理的图片
  getForeground: () => Store.get('foreground'),
  setForeground: (val) => Store.set('foreground', val),
  // 批量抠图处理的图片
  getForegroundList: () => Store.get('foregroundList'),
  setForegroundList: (val) => Store.set('foregroundList', val),
  // 批量抠图后处理的图片
  getHandledImageList: () => Store.get('handledImageList'),
  setHandledImageList: (val) => Store.set('handledImageList', val),
  // 抠图的原图
  getOriginImageList: () => Store.get('originImageList'),
  setOriginImageList: (val) => Store.set('originImageList', val),
  /*
    {
      name: '简历照片',
      width: '25',
      height: '35',
      imgSrc:''
    }
  */
  getCardInfo: () => {
    // return {
    //   name: '一寸',
    //   mm: '25*35',
    //   size: '295*413',
    //   dpi: '300',
    //   sizeRange: '',
    //   background: '',
    //   type: '寸照',
    //   requirementIndex: -1,
    //   width: '25',
    //   height: '35',
    //   unit: 'mm',
    //   w: '295',
    //   h: '413',
    //   actionType: 'cardCreate',
    //   imgSrc: Store.get('_cardImageSrc') || 'http://localhost/crop.jpg',
    //   fileName: 'd200cb8503632f203fdcf2dfb80fcb8f'
    // };
    return Store.get('cardInfo');
  },
  setCardInfo: (cardInfo) => {
    // 25*35mm，dpi=300时对应的像素为295*413px
    const dpiRatio = 11.8;
    if (cardInfo.unit == 'px') {
      if (!cardInfo.w) {
        // 此时cardInfo.width表示对应的px宽度
        cardInfo.w = cardInfo.width;
        cardInfo.h = cardInfo.height;
        cardInfo.width = Math.round(cardInfo.w / dpiRatio);
        cardInfo.height = Math.round(cardInfo.h / dpiRatio);
      }
    } else if (cardInfo.unit == 'mm') {
      if (!cardInfo.w) {
        cardInfo.w = Math.round(cardInfo.width * dpiRatio);
        cardInfo.h = Math.round(cardInfo.height * dpiRatio);
      }
    }
    Store.set('cardInfo', cardInfo);
  },
  // 生成的证件图片
  getCardImage: () => Store.get('cardImage'),
  setCardImage: (val) => Store.set('cardImage', val),
  // 生成的6存证件图片
  getSixCardImage: () => Store.get('sixCardImage'),
  setSixCardImage: (val) => Store.set('sixCardImage', val),
  getSixCardImageList: () => Store.get('sixCardImageList'),
  setSixCardImageList: (val) => Store.set('sixCardImageList', val),
  // 存储的自定义背景 Array[src、src...]
  getCustomBgList: () => Store.get('customBgList'),
  setCustomBgList: (val) => Store.set('customBgList', val),
  getHandledImage: () => Store.get('handledImage'),
  setHandledImage: (val) => Store.set('handledImage', val),
  // 证件照制作的图片
  getCardOriginImage: () => Store.get('cardOriginImage') || [],
  setCardOriginImage: (val) => Store.set('cardOriginImage', val),
  // 图片上传统计
  getStatisticImageUpload: () => Store.get('statisticImageUpload'),
  setStatisticImageUpload: (val) => {
    let StatisticImageUpload = {
      ...(ac.getStatisticImageUpload() || {}),
      ...val
    };
    Store.set('statisticImageUpload', StatisticImageUpload);
  },
  getHandleTimes: () => {
    let r = Store.get('handleTimes') || {};
    let now = dayjs(new Date()).format('YYYY-MM-DD');
    if (r && r.date && r.date === now) {
      return r.times || 0;
    } else {
      return 0;
    }
  },
  addHandleTimes: (times = 1) => {
    let r = Store.get('handleTimes') || {};
    let now = dayjs(new Date()).format('YYYY-MM-DD');
    if (r.date && r.date == now) {
      r.times += times;
    } else {
      r.date = now;
      r.times = 1;
    }
    Store.set('handleTimes', r);
  },
  getImageList: () => Store.get('imageList') || [],
  setImageList: (val) => Store.set('imageList', val),
  getKeySaveOneDay: (key) => {
    let r = Store.get(key) || {};
    let now = dayjs(new Date()).format('YYYY-MM-DD');
    if (r && r.date && r.date === now) {
      return r.times || 0;
    } else {
      return 0;
    }
  },
  setKeySaveOneDay: (key, times = 1) => {
    let r = Store.get(key) || {};
    let now = dayjs(new Date()).format('YYYY-MM-DD');
    if (r.date && r.date == now) {
      r.times += times;
    } else {
      r.date = now;
      r.times = 1;
    }
    Store.set(key, r);
  },
  setRectInfo({ imageSrc, currStatus }) {
    let rectInfo = Store.get('rectInfo') || {};
    let key = imageSrc.slice(imageSrc.length - 100, imageSrc.length - 1);
    rectInfo[key] = currStatus;
    Store.set('rectInfo', rectInfo);
  },
  getRectInfo(imageSrc) {
    let key = imageSrc.slice(imageSrc.length - 100, imageSrc.length - 1);
    let rectInfo = Store.get('rectInfo') || {};
    return rectInfo[key];
  }
};

export default ac;
